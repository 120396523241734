
import { mapActions, mapGetters } from 'vuex'
import UIModalMessage from '@/components/ui/UIModalMessage/UIModalMessage.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEButton from '@/components/pde/PDEButton.vue'

export default {
  name: 'TimeEntryModal',

  components: {
    UIModalMessage,
    PDEIcon,
    PDEButton,
  },

  computed: {
    ...mapGetters('account', ['timeEntryEnabled']),
    ...mapGetters('navigation', ['lockedTimeEntryModalOpen']),
  },

  methods: {
    ...mapActions('navigation', [
      'toggleLockedTimeEntryModal',
    ]),
  },
}
