
import { mapActions, mapGetters } from 'vuex'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'LeftNavToggle',
  components: { PDEIcon },

  computed: {
    ...mapGetters('navigation', ['leftNavExpanded', 'collapseIconDisplay']),
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    handleNavToggleClickEvent() {
      this.sendClickEvent({
        name: 'Nav State Click',
        text: this.leftNavExpanded ? 'Expand' : 'Collapse',
        area: this.leftNavExpanded ? '/left-nav' : '/left-nav-collapsed',
      })
    },

    toggle() {
      this.toggleLeftNav()
      this.handleNavToggleClickEvent()
    },
    ...mapActions('navigation', ['toggleLeftNav']),
  },
}
