
import Link from '@/components/ui/Link/Link.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',

  components: {
    'app-link': Link,
    PDEIcon,
  },
  emits: ['clickTrack'],

  computed: {
    ...mapGetters('account', ['division']),
    ...mapGetters('identity', ['loginName', 'userId']),
    copyValues() {
      return {
        questions: {
          copy: this.$t('account.footer.questions.copy'),
          link: this.$t(`account.contact.${this.division}.questions.link`),
          text: this.$t(`account.contact.${this.division}.questions.text`),
        },

        copyright: this.$t('account.footer.copyright.copyLine1', { year: new Date().getFullYear() }),

        feedback: {
          copy: this.$t('account.footer.feedback.copy'),
          link: this.$t('account.footer.feedback.link', { username: this.loginName, foxid: this.userId }),
          target: this.$t('account.footer.feedback.target'),
        },

        privacyPolicy: {
          copy: this.$t('account.footer.privacyPolicy.copy'),
          link: this.$t('account.footer.privacyPolicy.link'),
          target: this.$t('account.footer.privacyPolicy.target'),
        },

        termsAndConditions: {
          copy: this.$t('account.footer.termsAndConditions.copy'),
          link: this.$t('account.footer.termsAndConditions.link'),
          target: this.$t('account.footer.termsAndConditions.target'),
        },
      }
    },
  },
}
