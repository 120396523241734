
import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationModal from '@/components/account/ApplicationModal/ApplicationModal.vue'
import TimeEntryModal from '@/components/account/TimeEntryModal/TimeEntryModal.vue'
import UIFooter from '@/layouts/components/UIFooter/Footer.vue'
import getReadabilityClass from '@/mixins/getReadabilityClass/getReadabilityClass.js'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'
import LeftNavToggle from '@/layouts/components/LeftNavToggle/LeftNavToggle.vue'
import LeftNavItem from '@/layouts/components/LeftNav/LeftNavItem/LeftNavItem.vue'

export default {
  name: 'LeftNav',

  components: {
    ApplicationModal,
    LeftNavItem,
    LeftNavToggle,
    PDEIcon,
    PDESkeleton,
    TimeEntryModal,
    UIFooter,
  },

  mixins: [getReadabilityClass],

  data() {
    return {
      leftNavIsAnimating: false,
      showDesktopNav: false,
      navItemSkeletonWidths: [32, 58, 56, 56, 42],
    }
  },

  computed: {
    ...mapState('account', ['isAlliedUser', 'userDetailsLoaded']),
    ...mapState('banners', ['globalBannerHeight']),

    ...mapGetters('account', [
      'applicationEnabled',
      'division',
      'isFullUser',
      'isPartialUser',
    ]),
    ...mapGetters('application', ['otherDivisionApplicationCheckComplete']),
    ...mapGetters('documents', ['requested']),
    ...mapGetters('identity', ['loggedInAsRep']),
    ...mapGetters('navigation', ['primaryNavIsOpen', 'leftNavExpanded']),

    leftNavSpacing() {
      return {
        // UIHeader height is 64px
        top: `${!this.showDesktopNav ? (this.$smartBanner.height + this.globalBannerHeight + 64) : 0}px`,
      }
    },

    documentsRoute() {
      return this.isAlliedUser || this.isPartialUser || this.$globalVars.brand === 'gmedical' ? '/documents' : ''
    },

    showDocumentsSubMenu() {
      if (this.$globalVars.brand === 'gmedical') return false
      return !this.isAlliedUser && this.isFullUser
    },

    isExpanded() {
      return this.primaryNavIsOpen || this.leftNavExpanded
    },

    primaryNavigationContainerWidth() {
      if (!this.showDesktopNav) return 'w-0'

      return this.leftNavExpanded ? 'w-248' : 'w-64'
    },

    showDocumentsMenuItem() {
      // PDE-11194 will remove this check
      if (this.division === this.$globalVars.divisions.codes.CHA && !this.$featureFlags.enableChaDocuments10030) return false

      return this.$globalVars.documents.allowedDivisions.includes(this.division)
    },
  },

  watch: {
    leftNavExpanded() {
      this.leftNavIsAnimating = true
      setTimeout(() => {
        this.leftNavIsAnimating = false
      }, 500)
    },
  },

  beforeMount() {
    this.closePrimaryNav()

    this.handleWindowResize()
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  async mounted() {
    window.addEventListener('resize', this.handleWindowResize)
  },

  methods: {
    ...mapActions('navigation', [
      'closePrimaryNav',
      'navigateToAccountSettings',
      'navigateToAppPrepop',
      'openTimeEntryRedirectPage',
      'setCollapseIconDisplay',
      'toggleLockedApplicationModal',
      'toggleLockedTimeEntryModal',
    ]),

    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('application', ['navigateToApplicationPage']),
    ...mapActions('assignment', ['closeAssignmentWorksite']),

    handleFooterNavigation(event) {
      this.trackClickEvent({ name: 'Footer Click', text: event, area: '/footer' })
    },

    handleNavigateToTravel() {
      this.trackClickEvent({ text: 'Travel' })
    },

    handleNavigateToJobs() {
      this.closeAssignmentWorksite()
      this.trackClickEvent({ text: 'Assignments' })
    },

    handleNavigateToApplication() {
      this.trackClickEvent({ text: 'Application' })
      this.navigateToApplicationPage()
    },

    trackDocumentsMenuClick(state = '') {
      const text = this.isAlliedUser || this.isPartialUser ? 'Documents' : `Documents ${state}`
      this.trackClickEvent({ text, area: '/documents' })
    },

    handleWindowResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth >= 1200) {
        this.showDesktopNav = true
        this.closePrimaryNav()
      } else {
        this.showDesktopNav = false
      }
    },

    handleClosePrimaryNav($event) {
      if ($event.target.id === 'menu-icon') return

      this.closePrimaryNav()
    },

    trackClickEvent({ name = 'Left Navigation Click', text, area = '' }) {
      this.sendClickEvent({
        name,
        text,
        area: `/left-nav${area}`,
      })
    },
  },
}
