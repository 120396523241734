import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-full w-full" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "data-test": "pde-skeleton",
        class: _normalizeClass(["pde-skeleton w-full flex mx-auto", {'animate-pulse': $props.active, 'items-center': !$props.paragraph}])
      }, [
        ($props.avatar)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              "data-test": "pde-skeleton-avatar",
              class: _normalizeClass(["mr-16 shrink-0 bg-pureblack-5", $options.avatarClasses]),
              style: _normalizeStyle($options.avatarSize)
            }, null, 6))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              ($props.title)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    "data-test": "pde-skeleton-title",
                    class: _normalizeClass(["pde-skeleton-title bg-pureblack-5", {'mb-24 ': $props.paragraph}]),
                    style: _normalizeStyle({height: $options.titleHeight, width: $options.titleWidth})
                  }, null, 6))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paragraphRows, (n) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `pde-skeleton-paragraph-${n}`,
              "data-test": "pde-skeleton-paragraph",
              class: _normalizeClass(["pde-skeleton-paragraph h-16 mb-16 bg-pureblack-5", {'w-2/3': n === $options.paragraphRows}])
            }, null, 2))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}