
import PDEIconButton from '@/components/pde/PDEIconButton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'UIModalMessage',

  components: {
    PDEHeading,
    PDEIconButton,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['closeDialog'],
  methods: {
    closeButtonClicked() {
      this.$emit('closeDialog')
    },
    dialogOverlayClicked() {
      this.$emit('closeDialog')
    },
  },
}
