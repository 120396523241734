
import { mapActions, mapGetters } from 'vuex'
import UIModalMessage from '@/components/ui/UIModalMessage/UIModalMessage.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEButton from '@/components/pde/PDEButton.vue'

export default {
  components: {
    UIModalMessage,
    PDEIcon,
    PDEButton,
  },

  computed: {
    ...mapGetters('account', ['applicationEnabled']),
    ...mapGetters('navigation', ['lockedApplicationModalOpen']),
  },

  methods: {
    ...mapActions('navigation', ['toggleLockedApplicationModal']),
    ...mapActions('account', ['downloadApplication']),
  },
}
